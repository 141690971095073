import get from 'lodash.get';

export const configuratorConfirmationTransformers = page => {
  const configuratorConfLanguages = {};

  page.allSiteSettingEntityThankYouPages.edges.forEach(edge => {
    const data = edge.node;

    configuratorConfLanguages[data.langcode] = {
      configuratorConfirmationData: {
        heroImage: get(page, 'configuratorConfHero.childImageSharp', null),
        button: get(data, 'field_settings_button_text_2', ''),
        paragraph: get(data, 'field_text_1.value', ''),
        seo: {
          title: get(data, 'field_meta_title', ''),
          description: get(data, 'field_meta_description', ''),
        },
      },
    };
  });

  return configuratorConfLanguages;
};
