import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import ConfiguratorConfirmationComponent, {
  ConfiguratorConfirmationProps,
} from '@components/configuratorConfirmation';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DConfiguratorConfirmationPropsProps extends ConfiguratorConfirmationProps {}
const DConfiguratorConfirmation: React.FC<DConfiguratorConfirmationPropsProps> = (
  props: DConfiguratorConfirmationPropsProps
) => <ConfiguratorConfirmationComponent {...props} />;

const ConfiguratorConfirmationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DConfiguratorConfirmation);

export default ConfiguratorConfirmationConnected;
