import React, { Component } from 'react';
import { ConfiguratorConfirmationQueryProps } from '@graphql/queries/ConfiguratorConfirmation';
import Page from '@components/layout/Page';
import Hero from '@components/common/Hero';
import * as utils from '@utils';

export interface ConfiguratorConfirmationProps extends ConfiguratorConfirmationQueryProps {
  langcode: string;
}

class ConfiguratorConfirmationComponent extends Component<ConfiguratorConfirmationProps> {
  render() {
    const { configuratorConfirmationData, langcode } = this.props;

    return (
      <Page>
        <Hero
          size="medium"
          image={configuratorConfirmationData.heroImage}
          noImageOnMobile={true}
          paragraph={utils.SafeHtml(configuratorConfirmationData.paragraph)}
          // buttonText={configuratorConfirmationData.button}
          // buttonRoute="https://iposweb.pl/#/login"
          // buttonOutside={true}
          text="dark"
          noArrow={true}
        />
      </Page>
    );
  }
}

export default ConfiguratorConfirmationComponent;
