import React from 'react';

import ConfiguratorConfirmationContainer from '@containers/configuratorConfirmation';
import useConfiguratorConfirmationQuery from '@graphql/queries/ConfiguratorConfirmation';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface ConfiguratorConfirmationProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: ConfiguratorConfirmationProps) => {
  const data = useConfiguratorConfirmationQuery()[props.pageContext.langcode];

  return (
    <>
      <MetaPage
        title={data.configuratorConfirmationData.seo.title}
        description={data.configuratorConfirmationData.seo.description}
      />
      <DataLayer location={props.location} />
      <ConfiguratorConfirmationContainer {...data} langcode={props.pageContext.langcode} />
    </>
  );
};
