import { graphql, useStaticQuery } from 'gatsby';
import { ConfiguratorConfirmationDataProps } from '@content/types/configuratorConfirmation';
import * as configuratorConfirmationTransformers from '@content/transformers/ConfiguratorConfirmationTransformers';

export interface ConfiguratorConfirmationQueryProps {
  configuratorConfirmationData: ConfiguratorConfirmationDataProps;
}

const ConfiguratorConfirmationQuery = (): ConfiguratorConfirmationQueryProps => {
  const configuratorConfirmationQuery = useStaticQuery(graphql`
    query {
      configuratorConfHero: file(relativePath: { eq: "configurator-thankyoupage-hero@2x.jpg" }) {
        childImageSharp {
          fixed(width: 1600, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      allSiteSettingEntityThankYouPages {
        edges {
          node {
            langcode
            field_settings_button_text_2
            field_text_1 {
              value
            }
            field_meta_title
            field_meta_description
          }
        }
      }
    }
  `);

  return configuratorConfirmationTransformers.configuratorConfirmationTransformers(
    configuratorConfirmationQuery
  );
};

export default ConfiguratorConfirmationQuery;
